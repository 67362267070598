import axios, { AxiosInstance } from 'axios';
import { backendUrl } from '~/helpers/backend';

const unauthorizedApiClient: AxiosInstance = axios.create({
    baseURL: backendUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default unauthorizedApiClient;

<!-- eslint-disable vue/object-curly-newline -->
<template lang="pug">
teleport(to="#notifications")
    div(
        v-for="{ id, type, title, body } in notificationStore.notifications"
        :key="id"
    )
        v-alert(
            :color="type"
            :class="`mb-${SPACING_HALF}`"
        )
            v-alert-title(v-if="title") {{ title }}
            div(v-if="body") {{ body }}
</template>

<script setup lang="ts">
import { SPACING_HALF } from '~/constants/vuetify';

import { useNotificationStore } from '~/store/notification';

const notificationStore = useNotificationStore();
</script>

<style lang="scss">
#notifications {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99999;

    .v-alert {
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
    }
}
#notifications > * {
    pointer-events: initial;
}
</style>

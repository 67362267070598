import { AxiosResponse } from 'axios';

import type { LoginResponseBody, Client } from '~/interfaces/authentication';
import type { LocationQueryValue } from 'vue-router';
import type { ListResponse } from '~/interfaces/api';

import unauthorizedApiClient from '~/clients/unauthorizedApiClient';
import authorizedApiClient from '~/clients/authorizedApiClient';

// eslint-disable-next-line import/prefer-default-export
export function loginWithPassword(
    uuid: string,
    email: string,
    password: string,
): Promise<AxiosResponse<LoginResponseBody>> {
    return unauthorizedApiClient.post('/auth/login', {
        uuid,
        email,
        password,
    });
}

export function loginWithOneTimeToken(
    token: string,
): Promise<AxiosResponse<LoginResponseBody>> {
    return unauthorizedApiClient.post('/auth/exchange', {
        auth: token,
    });
}

export function signup(
    company: string,
    email: string,
    password: string,
    agb: boolean,
    future: boolean,
    signUpUuid: string,
): Promise<AxiosResponse<LoginResponseBody>> {
    return unauthorizedApiClient.post('/auth/signup', {
        company,
        email,
        password,
        agb,
        future,
        signUpUuid,
    });
}

export function forgot(
    email: string,
): Promise<AxiosResponse<LoginResponseBody>> {
    return unauthorizedApiClient.post('auth/password/request', {
        email,
    });
}

export function resend(
    email: string,
): Promise<AxiosResponse<LoginResponseBody>> {
    return unauthorizedApiClient.post('auth/resend', {
        email,
    });
}

export function resetPassword(
    token: string | string[],
    password: string,
): Promise<AxiosResponse<LoginResponseBody>> {
    return authorizedApiClient.post('auth/password/reset', {
        token,
        password,
    });
}

export function getToken(): Promise<AxiosResponse<ListResponse<Client>>> {
    return authorizedApiClient.get('auth/token', {});
}

export function addToken(desc: string, expiresIn: number) {
    return authorizedApiClient.post('/auth/token', {
        description: desc,
        expiresIn,
    });
}

export function deleteToken(uuid: string) {
    return authorizedApiClient.delete(`/auth/token?uuid=${uuid}`);
}
export function verify(token: string) {
    return authorizedApiClient.post('/auth/verify', {
        token,
    });
}

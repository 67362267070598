function getURL(): string | undefined {
    const { hostname } = window.location;

    switch (hostname) {
    case 'mein.bezahl.de':
    case 'future.bezahl.de':
    case 'dashboard.bezahl.de':
        return 'https://api.bezahl.de';

    case 'mein.partner.nx.bezahl.de':
    case 'future.partner.nx.bezahl.de':
    case 'dashboard.partner.nx.bezahl.de':
        return 'https://api.partner.nx.bezahl.de';

    case 'mein.local.nx-t.dev':
    case 'future.local.nx-t.dev':
    case 'dashboard.local.nx-t.dev':
        return 'https://api.local.nx-t.dev';

    default:
        // none;
    }

    const stack = hostname.replace(/mein-|future-|dashboard-|.nx-t\.dev/g, '');
    if (stack) {
        return `https://api-${stack}.nx-t.dev`;
    }
    console.error('Finding the correct api url failed');
    return undefined;
}

function getBackendURL(version = 1): string {
    return `${getURL()}/nxt/v${version}`;
}

export const backendUrl = getBackendURL();
export const backendUrlV6 = getBackendURL(6);
export const socketBackendUrl = getURL();

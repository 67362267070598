/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import App from '~/App.vue';

// Composables

// Plugins
import { registerPlugins } from '~/plugins';

const app = createApp(App);

const env = document.location.host.split('.')[1];
const environment = ['future.bezahl.de', 'dashboard.bezahl.de'].includes(document.location.host) ? 'production' : 'non-production';

if (env !== 'local') {
    Sentry.init({
        app,
        dsn: 'https://3e7d36221e7308c33f25e0102b408646@o4507532506497024.ingest.de.sentry.io/4507532563120208',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment,
        // filter out data or errors
        // beforeSend(event) {

        //     return event;
        // },
    });
}

registerPlugins(app);

app.mount('#app');

// Utilities
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import { AddNotification, Notification } from '~/interfaces/notification';

interface NotificationStoreState {
    notifications: Record<string, Notification>;
}

const TIMEOUT_DEFAULT = 5000;
const TIMEOUT_WARNING = 5000;
const TIMEOUT_ERROR = 10000;

// eslint-disable-next-line import/prefer-default-export
export const useNotificationStore = defineStore('notification', {
    state: (): NotificationStoreState => ({
        notifications: {},
    }),
    actions: {
        addNotification({ title, body, type }: AddNotification) {
            let timeout = TIMEOUT_DEFAULT;
            if (type === 'warning') timeout = TIMEOUT_WARNING;
            if (type === 'error') timeout = TIMEOUT_ERROR;
            const id = uuidv4();
            this.notifications[id] = {
                id, title, body, type,
            };
            setTimeout(() => delete this.notifications[id], timeout);
        },
    },
});

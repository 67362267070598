/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';

import { VStepper } from 'vuetify/components/VStepper';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    display: {
        thresholds: {
            xl: 1400,
        },
    },
    components: {
        VStepper,
    },
    theme: {
        themes: {
            light: {
                colors: {
                    header: '#009EFA',
                    title: '#009EFA',
                    tertiary: '#B5B5B5',
                },
            },
        },
    },
});

import { Directive, DirectiveBinding } from 'vue';

import { sanitize } from '~/helpers/sanitize';

export const vSanitizedHtml: Directive = {
    mounted(el: HTMLElement, binding: DirectiveBinding<unknown>, _: unknown, __: unknown) {
        // eslint-disable-next-line no-param-reassign
        el.innerHTML = sanitize(`${binding.value}`);
    },
    updated(el: HTMLElement, binding: DirectiveBinding<unknown>, _: unknown, __: unknown) {
        // eslint-disable-next-line no-param-reassign
        el.innerHTML = sanitize(`${binding.value}`);
    },
};

export default vSanitizedHtml;

import { defineStore } from 'pinia';

import type { Organization, OrganizationStoreState } from '~/interfaces/organization';

import * as organizationService from '~/services/organizationService';

// eslint-disable-next-line import/prefer-default-export
export const useOrganizationStore = defineStore('organization', {
    state: (): OrganizationStoreState => ({
        organizations: [],
        activeOrgId: null,
    }),
    getters: {
        activeOrganization: (state): Organization | undefined => state.organizations.find(({ id }) => id === state.activeOrgId),
    },
    actions: {
        async loadOrganizations(): Promise<void> {
            try {
                const res = await organizationService.getOrganizations();
                this.organizations = res.data.list;
            } catch (error) {
                // TODO: add error handling
            }
        },
    },
});

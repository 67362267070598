// Utilities
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import { LoginUser } from '~/interfaces/authentication';

import { ACCESS_TOKEN, AUTH_UUID, TOKEN_MISSING } from '~/constants/tokens';

import socket from '~/clients/socket';

import * as authenticationService from '~/services/authenticationService';
import * as userService from '~/services/userService';
import { type Socket } from 'socket.io-client';

const VUEX = 'vuex';
const VUEXID = 'vuexid';

const TOKENRENEWINTERVAL = 1000 * 60 * 3; // 1000 * 60 * 3 = 3 minutes

interface AuthenticationStoreState {
    user: LoginUser | null;
    vuexid: string | null;
    socket: Socket;
    tokenInterval: NodeJS.Timeout | undefined;
}

function getAuthUuid(): string {
    let authUuid = localStorage.getItem(AUTH_UUID);
    if (!authUuid) {
        authUuid = uuidv4();
        localStorage.setItem(AUTH_UUID, authUuid);
    }
    return authUuid;
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthenticationStore = defineStore('authentication', {
    state: (): AuthenticationStoreState => ({
        user: null,
        vuexid: null,
        socket,
        tokenInterval: undefined,
    }),
    getters: {
        isAuthenticated: (state): boolean => {
            const hasToken = `${localStorage.getItem(ACCESS_TOKEN) || ''}`.length > 0;
            const userLoaded = ![null, undefined].includes(state.user as any);
            return hasToken && userLoaded;
        },
    },
    actions: {
        bindEvents() {
            socket.on('auth.renewedToken', (res) => {
                localStorage.setItem(ACCESS_TOKEN, res.token);

                // NOTE: why / don't use vuex in pinia(?)
                // Looks like a hack to make old vux store compatible with new pinia store
                const vuexRaw = localStorage.getItem(VUEX);
                const vuex = JSON.parse(vuexRaw || '{}');
                vuex.token = res.token;
                vuex.user.token = res.token;
                localStorage.setItem(VUEX, JSON.stringify(vuex));
            });
        },
        async loadCurrentUser(): Promise<void> {
            return userService.getCurrentUser().then((res) => {
                this.user = res.data;
                this.user.token = localStorage.getItem(ACCESS_TOKEN) || undefined;
                if (!this.user.activeLanguage) this.user.activeLanguage = navigator.language;
                if (!this.user.language) this.user.language = 'de';
                if (!localStorage.getItem(VUEXID)) localStorage.setItem(VUEXID, uuidv4());
                const vuexRaw = localStorage.getItem(VUEX);
                let vuex = JSON.parse(vuexRaw || '{}');
                if (!vuex || !vuex.user) vuex = { user: {} };
                Object.assign(vuex.user, this.user);
                vuex.token = this.user.token;
                localStorage.setItem(VUEX, JSON.stringify(vuex));

                this.initTokenRefresh();
            });
        },
        async authenticate(email: string, password: string): Promise<void> {
            const res = await authenticationService.loginWithPassword(
                getAuthUuid(),
                email,
                password,
            );
            if (!res.data.token) throw new Error(TOKEN_MISSING);
            localStorage.setItem(ACCESS_TOKEN, res.data.token);
            return this.loadCurrentUser();
        },
        async signup(company: string, email: string, password: string, agb: boolean, future: boolean, signUpUuid: string) {
            await authenticationService.signup(
                company,
                email,
                password,
                agb,
                future,
                signUpUuid,
            );
        },
        async forgot(email: string) {
            await authenticationService.forgot(
                email,
            );
        },
        async resend(email: string) {
            await authenticationService.resend(
                email,
            );
        },
        async resetPassword(token: string | string[], password: string) {
            await authenticationService.resetPassword(
                token,
                password,
            );
        },
        async authenticateWithOneTimeToken(token: string): Promise<void> {
            const res = await authenticationService.loginWithOneTimeToken(token);
            if (!res.data.token) throw new Error(TOKEN_MISSING);
            localStorage.setItem(ACCESS_TOKEN, res.data.token);
            return this.loadCurrentUser();
        },
        resetUser(): void {
            clearInterval(this.tokenInterval);
            this.tokenInterval = undefined;
            this.user = null;
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(VUEX);
            localStorage.removeItem(VUEXID);
        },
        async initUser(): Promise<void> {
            const vuexRaw = localStorage.getItem(VUEX);
            const vuexid = localStorage.getItem(VUEXID);
            if (vuexRaw && vuexid) {
                const vuex = JSON.parse(vuexRaw);
                if (vuex.user) {
                    this.user = vuex.user;
                    if (this.user && this.user.token) localStorage.setItem(ACCESS_TOKEN, this.user.token);
                }
            }
            const token = localStorage.getItem(ACCESS_TOKEN);
            if (token?.length) return this.loadCurrentUser();
            return Promise.resolve();
        },
        async initTokenRefresh() {
            const token = localStorage.getItem(ACCESS_TOKEN);

            if (!socket.connected) {
                if (token?.length) {
                    socket.auth = { token };
                    socket.connect();
                }
            }
            if (this.tokenInterval) {
                return;
            }

            if (!token) {
                this.socket.disconnect();
            }

            const tokenInterval = setInterval(() => {
                const activeToken = localStorage.getItem(ACCESS_TOKEN);
                if (!this.socket.connected) {
                    this.resetUser();
                    return;
                }
                this.socket.emit('auth.renewToken', { token: activeToken });
            }, TOKENRENEWINTERVAL);
            this.tokenInterval = tokenInterval;
        },
        async verify(token: string) {
            await authenticationService.verify(
                token,
            );
        },
    },
});

import DOMPurify from 'dompurify';

const options: DOMPurify.Config = {
    ALLOWED_TAGS: [
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'div', 'hr', 'li', 'ol', 'p', 'pre',
        'ul', 'a', 'br', 'span', 'strong', 'sub', 'sup', 'i', 'b', 'strong',
        'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr',
    ],
};

// eslint-disable-next-line import/prefer-default-export
export function sanitize(html: string): string {
    return DOMPurify.sanitize(html, options).toString();
}

import { Color } from '~/interfaces/theme';

// eslint-disable-next-line import/prefer-default-export
export function hex2rgb(hex: string): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // return {r, g, b}
    return `rgb(${r}, ${g}, ${b})`;
}

export function colorToRGBA(color: Color): string {
    const {
        r, g, b, a,
    } = color;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function colorToRGB(color: Color): string {
    const {
        r, g, b,
    } = color;
    return `rgb(${r}, ${g}, ${b})`;
}

export function stringToColor(value: string): Color {
    const color: Color = { r: 0, g: 0, b: 0 };
    if (value.includes(',')) {
        const [r, g, b] = value.split(',');
        color.r = Number.parseInt(r, 10) || 0;
        color.g = Number.parseInt(g, 10) || 0;
        color.b = Number.parseInt(b, 10) || 0;
    } else {
        const hex = value.replaceAll('#', '');
        if (hex.length === 3) {
            const r = hex.substring(0, 1);
            const g = hex.substring(1, 2);
            const b = hex.substring(2, 3);
            color.r = Number.parseInt(r.padEnd(2, r), 16);
            color.g = Number.parseInt(g.padEnd(2, g), 16);
            color.b = Number.parseInt(b.padEnd(2, b), 16);
        } else {
            const r = hex.substring(0, 2);
            const g = hex.substring(2, 4);
            const b = hex.substring(4, 6);
            color.r = Number.parseInt(r, 16);
            color.g = Number.parseInt(g, 16);
            color.b = Number.parseInt(b, 16);
        }
    }
    return color;
}

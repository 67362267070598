export const SUPPORTED_LOCALES: string[] = [
    'de',
    'en',
    'es',
    'fr',
    'it',
    // 'pl',
    // 'ru',
];

export const DEFAULT_LOCALE = 'de';

/**
 * For saving the language set by the user in the
 * local storage.
 */
export const PREFERRED_LANGUAGE_KEY = 'preferredLanguage';

import { createI18n, I18nOptions } from 'vue-i18n';

import messagesDe from '~/i18n/de.json';
import messagesEn from '~/i18n/en.json';
import messagesEs from '~/i18n/es.json';
import messagesFr from '~/i18n/fr.json';
import messagesIt from '~/i18n/it.json';
// import messagesPl from '~/i18n/pl.json';
// import messagesRu from '~/i18n/ru.json';

import { DEFAULT_LOCALE } from '~/constants/internationalization';

export const messages = {
    de: messagesDe,
    en: messagesEn,
    es: messagesEs,
    fr: messagesFr,
    it: messagesIt,
    // pl: messagesPl,
    // ru: messagesRu,
};

export function getLanguageFromLocale(locale: string): string | null {
    if (locale.length === 2) {
        return locale.toLocaleLowerCase();
    }
    let parts = locale.split('-');
    if (parts.length > 0) {
        if (parts[0].length === 2) {
            return parts[0].toLocaleLowerCase();
        }
    }
    parts = locale.split('_');
    if (parts.length > 0) {
        if (parts[0].length === 2) {
            return parts[0].toLocaleLowerCase();
        }
    }
    return null;
}

// 2. Create i18n instance with options
export const i18nOptions: I18nOptions = {
    legacy: false,
    locale: DEFAULT_LOCALE, // set locale
    fallbackLocale: DEFAULT_LOCALE, // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
    warnHtmlInMessage: 'off',
    fallbackWarn: false,
    missingWarn: false,
};

const i18n = createI18n(i18nOptions);

export default i18n;

import { AxiosResponse } from 'axios';

import { LoginUser } from '~/interfaces/authentication';

import authorizedApiClient from '~/clients/authorizedApiClient';

// eslint-disable-next-line import/prefer-default-export
export function getCurrentUser(): Promise<AxiosResponse<LoginUser>> {
    return authorizedApiClient.get('/user');
}

export function getUser(): Promise<AxiosResponse<LoginUser>> {
    return authorizedApiClient.get('/user');
}

export function setDashboard(orgId: string, dashboard: string) {
    return authorizedApiClient.post('/user/activedashboard', { orgId, dashboard });
}

export function setProfileLanguage(language: string | undefined) {
    return authorizedApiClient.post('/user/setLanguage', { language });
}

export function lockUser(userId: string) {
    return authorizedApiClient.post('/lockUser', { id: userId });
}

export function requestPassword(email: string | undefined) {
    return authorizedApiClient.post('/auth/password/request', { email });
}

export function giveFeedback(feedback: string) {
    return authorizedApiClient.post('/user/emergencyswitch', { feedback });
}

export function swanAuthorization(code: string, state: string) {
    return authorizedApiClient.post('/swan/authorizationCode', { code, state });
}

export function archive(orgId: string, archived: 'open' | 'archived' | 'locked') {
    if (archived === 'open') {
        return authorizedApiClient.post(`/organization/${orgId}/archive`, { id: orgId });
    }
    return authorizedApiClient.post(`/organization/${orgId}/reopen`, { id: orgId });
}

export function setUserContactData(
    name?: string,
    surname?: string,
    companyrole?: string,
    mobilenumber?: number,
    street?: string,
    streetnumber?: number,
    zip?: number,
    city?: string,
) {
    return authorizedApiClient.post(
        '/user/settings',
        {
            contactData: {
                name,
                surname,
                companyrole,
                mobilenumber,
                street,
                streetnumber,
                zip,
                city,
            },
        },
    );
}

export function updateSubsciptions(subscriptions: string) {
    authorizedApiClient.post(
        '/user/subscription',
        {
            subscription: [
                subscriptions,
            ],
        },
    );
}

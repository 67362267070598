/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import type { App } from 'vue';

import vuetify from '~/plugins/vuetify';
import pinia from '~/store';
import router from '~/router';
import i18n from '~/i18n';
import vSanitizedHtml from '~/directives/vSanitizedHtml';

// Types

// eslint-disable-next-line import/prefer-default-export
export function registerPlugins(app: App) {
    app
        .use(vuetify)
        .use(router)
        .use(pinia)
        .use(i18n)

        .directive('sanitized-html', vSanitizedHtml);
}

// eslint-disable-next-line import/prefer-default-export
export const ELEVATION_DEFAULT = 2;
export const ELEVATION_INNER = 4;

export const SPACING_DEFAULT = 6;
export const SPACING_REDUCED = 4;
export const SPACING_HALF = SPACING_DEFAULT * 0.5;

export const MAX_WIDTH_SMALL = '600px';

export const WIDTH_MODAL = '1024px';
export const WIDTH_MODAL_SMALL = '820px';

// Utilities
import { defineStore } from 'pinia';

import type {
    BorderRadii, Elevations, ThemeColors,
} from '~/interfaces/theme';
import type { WhitelabelThemeResponse } from '~/interfaces/whitelabel';
import type { NavigationConfig, FooterConfig } from '~/interfaces/navigation';

import { ELEVATION_DEFAULT, ELEVATION_INNER } from '~/constants/vuetify';

import { stringToColor } from '~/helpers/theming';

const defaultColors: ThemeColors = {
    background: {
        r: 246, g: 246, b: 246,
    },
    'on-background': {
        r: 0, g: 0, b: 0,
    },

    surface: {
        r: 255, g: 255, b: 255,
    },
    'surface-bright': {
        r: 255, g: 255, b: 255,
    },
    'surface-variant': {
        r: 66, g: 66, b: 66,
    },
    'on-surface': {
        r: 0, g: 0, b: 0,
    },
    'on-surface-bright': {
        r: 0, g: 0, b: 0,
    },
    'on-surface-variant': {
        r: 0, g: 0, b: 0,
    },

    primary: {
        r: 0, g: 158, b: 250,
    },
    'primary-darken-1': {
        r: 55, g: 0, b: 179,
    },
    'on-primary': {
        r: 255, g: 255, b: 255,
    },
    'on-primary-darken-1': {
        r: 255, g: 255, b: 255,
    },

    secondary: {
        r: 242, g: 61, b: 101,
    },
    'secondary-darken-1': {
        r: 1, g: 135, b: 134,
    },
    'on-secondary': {
        r: 255, g: 255, b: 255,
    },
    'on-secondary-darken-1': {
        r: 255, g: 255, b: 255,
    },

    tertiary: {
        r: 181, g: 181, b: 181,
    },
    'on-tertiary': {
        r: 255, g: 255, b: 255,
    },

    error: {
        r: 176, g: 0, b: 32,
    },
    'on-error': {
        r: 255, g: 255, b: 255,
    },
    info: {
        r: 33, g: 150, b: 243,
    },
    'on-info': {
        r: 255, g: 255, b: 255,
    },
    success: {
        r: 76, g: 175, b: 80,
    },
    'on-success': {
        r: 255, g: 255, b: 255,
    },
    warning: {
        r: 251, g: 140, b: 0,
    },
    'on-warning': {
        r: 255, g: 255, b: 255,
    },
    header: {
        r: 0, g: 158, b: 250,
    },
    'on-header': {
        r: 255, g: 255, b: 255,
    },

    title: {
        r: 0, g: 158, b: 250,
    },
};

const defaultBorderRadii: BorderRadii = {
    'border-radius-alerts': 4,
    'border-radius-buttons': 4,
    'border-radius-cards': 4,
    'border-radius-inputs': 4,
};

const defaultElevations: Elevations = {
    'elevation-alerts': ELEVATION_DEFAULT,
    'elevation-buttons': ELEVATION_DEFAULT,
    'elevation-cards': ELEVATION_DEFAULT,
    'elevation-inner-cards': ELEVATION_INNER,
    'elevation-header': ELEVATION_DEFAULT,
};

interface ThemeStoreState {
    colors: ThemeColors;
    borderRadii: BorderRadii;
    elevations: Elevations;
    navigationConfig: NavigationConfig;
    footerConfig: FooterConfig;
}

// eslint-disable-next-line import/prefer-default-export
export const useThemeStore = defineStore('theme', {
    state: (): ThemeStoreState => ({
        colors: defaultColors,
        borderRadii: defaultBorderRadii,
        elevations: defaultElevations,
        navigationConfig: {},
        footerConfig: {},
    }),
    actions: {
        async loadTheme(theme: WhitelabelThemeResponse): Promise<void> {
            switch (theme.settings['border-radius']) {
            case 'l':
                this.borderRadii['border-radius-alerts'] = 12;
                this.borderRadii['border-radius-buttons'] = 24;
                this.borderRadii['border-radius-cards'] = 12;
                this.borderRadii['border-radius-inputs'] = 12;
                break;
            case 'm':
                this.borderRadii['border-radius-alerts'] = 8;
                this.borderRadii['border-radius-buttons'] = 8;
                this.borderRadii['border-radius-cards'] = 8;
                this.borderRadii['border-radius-inputs'] = 8;
                break;
            case 's':
            default:
                this.borderRadii['border-radius-alerts'] = 0;
                this.borderRadii['border-radius-buttons'] = 0;
                this.borderRadii['border-radius-cards'] = 0;
                this.borderRadii['border-radius-inputs'] = 0;
                break;
            }
            Object.keys(this.elevations).forEach((key) => {
                if (!theme.settings.elevations) return;
                const value = theme.settings.elevations[key as keyof Elevations];
                if (value !== undefined) {
                    this.elevations[key as keyof Elevations] = value as number;
                }
            });
            Object.keys(this.colors).forEach((key) => {
                if (!theme.settings.themeColors) return;
                const value = theme.settings.themeColors[key as keyof ThemeColors];
                if (value !== undefined) {
                    this.colors[key as keyof ThemeColors] = stringToColor(value as unknown as string);
                }
            });
            this.navigationConfig = theme.settings.navigationConfig || {};
            this.footerConfig = theme.settings.footerConfig || {};
        },
    },
});
